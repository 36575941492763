/** @format */

const data = [
  // {
  //   id: "dashboards",
  //   icon: "iconsminds-shop-4",
  //   label: "menu.dashboards",
  //   to: "/app/dashboards",
  //   subs: [
  //     {
  //       icon: "simple-icon-briefcase",
  //       label: "menu.default",
  //       to: "/app/dashboards/default",
  //     },
  //     {
  //       icon: "simple-icon-pie-chart",
  //       label: "menu.analytics",
  //       to: "/app/dashboards/analytics",
  //     },
  //     {
  //       icon: "simple-icon-basket-loaded",
  //       label: "menu.ecommerce",
  //       to: "/app/dashboards/ecommerce",
  //     },
  //     {
  //       icon: "simple-icon-doc",
  //       label: "menu.content",
  //       to: "/app/dashboards/content",
  //     },
  //   ],
  // },
  {
    id: "pages",
    icon: "iconsminds-digital-drawing",
    label: "menu.pages",
    to: "/app/pages",
    subs: [
      {
        icon: "iconsminds-dollar-sign-2",
        label: "menu.Bus",
        to: "/app/pages/Bus",
      },
      {
        icon: "iconsminds-dollar-sign-2",
        label: "menu.Station",
        to: "/app/pages/Station",
      },
      {
        icon: "iconsminds-dollar-sign-2",
        label: "menu.BusLine",
        to: "/app/pages/BusLine",
      },
    ],
  },
];
export default data;
