/** @format */

import React, { Component } from "react";

import { Card } from "reactstrap";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import Alert from "../../../../../../components/common/Alert";
import { Colxx } from "../../../../../../components/common/CustomBootstrap";
import EditModal from "../edit";

import { Station } from "../../../../../../api/Api";

export default class DataListView extends Component {
  state = {
    modalEditOpen: false,

    modal: false,
    modalRight: false,
    modalLarge: false,
    modalSmall: false,
    modalLong: false,
    modalBack: false,
    backdrop: true,
  };
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleEditModal = () => {
    this.setState({
      modalEditOpen: !this.state.modalEditOpen,
    });
  };

  deleteItem = async (id) => {
    try {
      await Station.deleteStation(id);
      await this.props.dataListRender();
      this.toggle();
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { product, isSelect, collect, onCheckItem } = this.props;
    return (
      <>
        <Colxx xxs="12" className="mb-3">
          <ContextMenuTrigger id="menu_id" data={product.id} collect={collect}>
            <Card
              onClick={(event) => onCheckItem(event, product.id)}
              className={classnames("d-flex flex-row", {
                active: isSelect,
              })}
            >
              <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <p className="list-item-heading mb-1 truncate">{product.name}</p>
                </div>
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <a className="list-item-heading mb-1 truncate" href={product.file}>
                    file
                  </a>
                </div>

                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <p className="list-item-heading mb-1 truncate">
                    Lat: {product?.location?.coordinates[0]}
                  </p>
                </div>
                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <p className="list-item-heading mb-1 truncate">
                    Lon: {product?.location?.coordinates[1]}
                  </p>
                </div>
                <div className="custom-control custom-checkbox align-self-center pr-4 ">
                  <Alert
                    modal={this.state.modal}
                    toggle={this.toggle}
                    deleteItem={() => this.deleteItem(product.id)}
                  />
                  <EditModal
                    modalOpen={this.state.modalEditOpen}
                    toggleModal={this.toggleEditModal}
                    dataListRender={this.props.dataListRender}
                    product={product}
                  />
                  <i
                    onClick={this.toggle}
                    className="simple-icon-trash  mb-2 mr-2 btn btn-outline-danger"
                  />
                  {/* <i
                    onClick={this.toggleEditModal}
                    className="simple-icon-pencil  mb-2 mr-2  btn btn-outline-primary"
                  /> */}
                </div>
              </div>
            </Card>
          </ContextMenuTrigger>
        </Colxx>
      </>
    );
  }
}

// /* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
// export default React.memo(DataListView);
