const data = [
  {
    title: 'Very informative content, thank you. ',
    detail: 'Mayra Sibley | Tea Loaf with Fresh Oranges | 17.09.2018 - 04:45',
    thumb: '/assets/img/profile-pic-l.jpg',
    rate: 5,
    key: 0
  },
  {
    title: 'This article was delightful to read. Please keep them coming.',
    detail: 'Barbera Castiglia | Cheesecake with Chocolate Cookies | 15.08.2018 - 01:18',
    thumb: '/assets/img/profile-pic-l-2.jpg',
    rate: 4,
    key: 1
  },
  {
    title: 'Your post is bad and you should feel bad.',
    detail: 'Bao Hathaway | Homemade Cheesecake | 26.07.2018 - 11:14',
    thumb: '/assets/img/profile-pic-l-3.jpg',
    rate: 5,
    key: 2
  },
  {
    title: 'Very original idea!',
    detail: 'Lenna Majeed | Tea Loaf with Fresh Oranges | 17.06.2018 - 09:20',
    thumb: '/assets/img/profile-pic-l-4.jpg',
    rate: 3,
    key: 3
  },
  {
    title: 'This article was delightful to read. Please keep them coming.',
    detail: 'Esperanza Lodge | Cheesecake with Fresh Berries | 16.06.2018 - 16:45',
    thumb: '/assets/img/profile-pic-l-5.jpg',
    rate: 2,
    key: 4
  },
  {
    title: 'Nah, did not like it.',
    detail: '24.07.2018 - 15:00',
    thumb: '/assets/img/profile-pic-l-2.jpg',
    rate: 5,
    key: 5
  },
  {
    title: 'Laree Munsch',
    detail: 'Brynn Bragg | Wedding Cake with Flowers | 12.04.2018 - 12:45',
    thumb: '/assets/img/profile-pic-l.jpg',
    rate: 4,
    key: 6
  }
]
export default data
