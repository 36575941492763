/**
 * Gogo Language Texts
 *
 * Table of Contents
 *
 * 01.General
 * 02.User Login, Logout, Register
 * 03.Menu
 * 04.Dashboards
 * 05.Pages
 * 06.Applications
 *   06.01.Chat
 *   06.02.Survey
 *   06.03.Todo
 * 07.UI
 *   07.01.Alerts
 *   07.02.Badges
 *   07.03.Buttons
 *   07.04.Cards
 *   07.05.Carousel
 *   07.06.Charts
 *   07.07.Collapse
 *   07.08.Dropdowns
 *   07.09.Editors
 *   07.10.Forms
 *   07.11.Form Components
 *   07.12.Icons
 *   07.13.Input Groups
 *   07.14.Jumbotron
 *   07.15.Modal
 *   07.16.Navigation
 *   07.17.Popover & Tooltip
 *   07.18.Sortable
 *   07.19.Maps
 *   07.20.Tables
 *
 * @format
 */

module.exports = {
  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "ورود",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "رمز",
  // "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.login-button": "ورود",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "نام کاربری",

  /* 03.Menu */
  "menu.app": "خانه",
  "menu.Bus": "اتوبوس",
  "menu.Station": "ایستگاه",
  "menu.BusLine": "خط ",
  "menu.dashboards": "Dashboards",
  "menu.default": "Default",
  "menu.analytics": "Analytics",
  "menu.ecommerce": "Ecommerce",
  "menu.content": "محصولات",
  "menu.content.notTree": " همه محصولات ",
  "menu.pages": "صفحات",
  "menu.sub": "اشتراکات",
  "menu.data-list": "Data List",
  "menu.Currency": "ارز",
  "menu.ContentUIPosition": "محتوای ویترین",
  "menu.UIPosition": "جایگاه یوآیی",
  "menu.CategoryAttribute": "ویژگی دسته‌بندی‌ها",
  "menu.ProductPricingVariantValue": "مشاهده محصول همراه با قیمت ",
  "menu.ProductAttributeValue": "مقدار ویژگی محصولات",
  "menu.ProductPricing": "قیمت گذاری محصول/محدودیت ارسال",
  "menu.Banner": "بنر",
  "menu.TitleOnlyShowCase": "ویترین‌های عنوان‌دار",
  "menu.TitleWithActionShowCase": "ویترین‌های عنوان‌دار و با عملکرد",
  "menu.Container": "دسته‌بندی ویژگی",
  "menu.Attribute": "ویژگی",
  "menu.AttributeValue": "مقدار ویژگی",
  "menu.Variant": "تنوع محصول تاثیرگذار در قیمت",
  "menu.VarientValue": "  مقادیر تنوع محصول",
  "menu.Seller": "تامین کننده",
  "menu.ProductStatus": "وضعیت محصول",
  "menu.Producer": "تولیدکننده",
  "menu.Metatag": " تگ",
  "menu.Invoices": "فاکتور",
  "menu.InventoryStatus": "وضعیت موجودی انبار",
  "menu.Brand": "برند",
  "menu.Warehouse": "انبار",
  "menu.ProductWarehouseInventory": "مشاهده ی تعداد محصولات انبار",
  "menu.ProductTab": "اطلاعات تکمیلی محصول",
  "menu.ProductTabDetail": "توضیحات تکمیلی",
  "menu.Store": "فروشگاه",
  "menu.Customer": "خریدار",
  "menu.ZoneType": "نوع موقعیت مکانی",
  "menu.UnitType": "نوع واحد",
  "menu.Tag": "تگ",
  "menu.Unit": " واحد",
  "menu.Zone": " موقعیت مکانی",
  "menu.Tax": "مالیات",
  "menu.Category": "دسته بندی",
  "menu.User-Ban": "کاربران مسدود شده",
  "menu.User": "کاربران  ",
  "name-model": "مدل محصول ",
  "menu.Slider": "اسلایدر",
  "menu.Mentor": "مربیان",
  "menu.thumb-list": "Thumb List",
  "menu.image-list": "Image List",
  "menu.details": "Details",
  "menu.search": "Search",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.forgot-password": "Forgot Password",
  "menu.error": "Error",
  "menu.applications": "Applications",
  "menu.todo": "To-do List",
  "menu.survey": "Survey",
  "menu.chat": "Chat",
  "menu.ui": "UI",
  "menu.alerts": "Alerts",
  "menu.badges": "Badges",
  "menu.buttons": "Buttons",
  "menu.cards": "Cards",
  "menu.carousel": "Carousel",
  "menu.charts": "Charts",
  "menu.collapse": "Collapse",
  "menu.dropdowns": "Dropdowns",
  "menu.editors": "Editors",
  "menu.form-layouts": "Form Layouts",
  "menu.form-components": "Form Components",
  "menu.form-validations": "Form Validations",
  "menu.icons": "Icons",
  "menu.input-groups": "Input Groups",
  "menu.jumbotron": "Jumbotron",
  "menu.modal": "Modal",
  "menu.navigation": "Navigation",
  "menu.popover-tooltip": "Popover & Tooltip",
  "menu.sortable": "Sortable",
  "menu.tables": "Tables",
  "menu.menu": "Menu",
  "menu.subhidden": "Subhidden",
  "menu.hidden": "Hidden",
  "menu.visible": "Visible",
  "menu.maps": "Maps",

  "menu.landingpage": "Landing Page",
  "menu.multipage-home": "Multipage Home",
  "menu.singlepage-home": "Singlepage Home",
  "menu.about": "About",
  "menu.auth-login": "Auth Login",
  "menu.auth-register": "Auth Register",
  "menu.blog": "Blog",
  "menu.blog-detail": "Blog Detail",
  "menu.careers": "Careers",
  "menu.confirmation": "Confirmation",
  "menu.contact": "Contact",
  "menu.docs": "Docs",
  "menu.features": "Features",
  "menu.prices": "Prices",
  "menu.videos": "Videos",
  "menu.mailing": "Mailing",
  "menu.invoice": "Invoice",
  "menu.blank-page": "Blank Page",
  "menu.types": "Menu Types",
  "menu.levels": "Menu Levels",
  "menu.third-level-1": "Third Level 1",
  "menu.third-level-2": "Third Level 2",
  "menu.third-level-3": "Third Level 3",

  /* 04.Dashboards */
  "dashboards.pending-orders": "Pending Orders",
  "dashboards.completed-orders": "Completed Orders",
  "dashboards.refund-requests": "Refund Requests",
  "dashboards.new-comments": "New Comments",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.recent-orders": "Recent Orders",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "به پنل ما خوش آمدید",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Pages */
  "pages.add-new": "اضافه کردن",
  "pages.add-new-modal-title": "ثبت",
  "pages.add-new-modal-cat": " ثبت دسته بندی ",
  "pages.add-new-modal-Zone": " ثبت موقعیت مکانی ",
  "pages.add-new-modal-Seller": " ثبت تامین کننده ",
  "pages.add-new-modal-Store": " ثبت فروشگاه ",
  "pages.add-new-modal-Customer": " ثبت خریدار ",
  "pages.add-new-modal-content": " ثبت محتوا ",
  "pages.add-new-modal-sub": "ثبت اشتراک",
  "pages.add-new-modal-user": "ثبت کاربر",
  "pages.add-new-modal-Currency": "ثبت ارز",
  "pages.add-new-modal-ContentUIPosition": "محتوای ویترین",
  "pages.add-new-modal-UIPosition": "ثبت جایگاه یوآِی",
  "pages.add-new-modal-CategoryAttribute": "ثبت ویژگی دسته‌بندی‌ها",
  "pages.add-new-modal-ProductAttributeValue": "ثبت مقدار ویژگی محصولات",
  "pages.add-new-modal-ProductPricing": "ثبت قیمت گذاری محصول",
  "pages.add-new-modal-Banner": "ثبت بنر",
  "pages.add-new-modal-TitleOnlyShowCase": "ثبت ویترین‌های عنوان‌دار",
  "pages.add-new-modal-TitleWithActionShowCase": "ثبت ویترین‌های عنوان‌دار و با عملکرد",
  "pages.add-new-modal-Container": "ثبت دسته‌بندی ویژگی",
  "pages.add-new-modal-AttributeValue": "ثبت مقدار ویژگی",
  "pages.add-new-modal-Attribute": "ثبت ویژگی",
  "pages.add-new-modal-VarientValue": "ثبت مقادیر تنوع محصول",
  "pages.add-new-modal-Variant": "ثبت تنوع محصول تاثیرگذار در قیمت",
  "pages.add-new-modal-zone-type": "ثبت نوع موقعیت مکانی",
  "pages.add-new-modal-Tax": "ثبت مالیات",
  "pages.add-new-modal-Unit": "ثبت واحد",
  "pages.add-new-modal-UnitType": "ثبت واحد",
  "pages.add-new-modal-slider": "ثبت اسلایدر",

  "pages.add-new-modal-Brand": "ثبت برند",
  "pages.add-new-modal-Warehouse": "ثبت انبار",
  "pages.add-new-modal-ProductTab": "ثبت اطلاعات تکمیلی محصول",
  "pages.add-new-modal-ProductTabDetail": "ثبت توضیحات تکمیلی",
  "pages.add-new-modal-InventoryStatus": "ثبت وضعیت موجودی انبار",
  "pages.add-new-modal-Metatag": "ثبت  تگ",
  "pages.add-new-modal-Invoices": "ثبت فاکتور",
  "pages.add-new-modal-Producer": "ثبت تولیدکننده",
  "pages.add-new-modal-ProductStatus": "ثبت وضعیت محصول",

  "pages.edit-new-modal-cat": " ویرایش دسته بندی ",
  "pages.edit-new-modal-Zone": " ویرایش موقعیت مکانی ",
  "pages.edit-new-modal-Store": " ویرایش فروشگاه ",
  "pages.edit-new-modal-Seller": " ویرایش تامین کننده ",
  "pages.edit-new-modal-Customer": " ویرایش خریدار ",
  "pages.edit-new-modal-Invoices": "تغییر حالت ارسال ",
  "pages.edit-new-modal-content": " ویرایش محتوا ",
  "pages.edit-new-modal-sub": "ویرایش اشتراک",
  "pages.edit-new-modal-user": "ویرایش کاربر",
  "pages.edit-new-modal-Currency": "ویرایش ارز",
  "pages.edit-new-modal-ContentUIPosition": "ویرایش محتوای ویترین",
  "pages.edit-new-modal-UIPosition": "ویرایش جایگاه یوآِی",
  "pages.edit-new-modal-CategoryAttribute": "ویرایش ویژگی دسته‌بندی‌ها",
  "pages.edit-new-modal-ProductAttributeValue": "ویرایش مقدار ویژگی محصولات",
  "pages.edit-new-modal-ProductPricing": "ویرایش قیمت گذاری محصول",
  "pages.edit-new-modal-VariantValueOpen": "اضافه کردن رابطه pricing وVariantValue ",
  "pages.edit-new-modal-Banner": "ویرایش بنر",
  "pages.edit-new-modal-TitleOnlyShowCase": "ویرایش ویترین‌های عنوان‌دار",
  "pages.edit-new-modal-TitleWithActionShowCase": "ویرایش ویترین‌های عنوان‌دار و با عملکرد",
  "pages.edit-new-modal-Container": "ویرایش دسته‌بندی ویژگی",
  "pages.edit-new-modal-Attribute": "ویرایش ویژگی",
  "pages.edit-new-modal-AttributeValue": "ویرایش مقدار ویژگی",
  "pages.edit-new-modal-Variant": "ویرایش تنوع محصول تاثیرگذار در قیمت",
  "pages.edit-new-modal-VarientValue": "ویرایش مقادیر تنوع محصول",
  "pages.edit-new-modal-zone-type": "ویرایش نوع موقعیت مکانی",
  "pages.edit-new-modal-Tax": "ویرایش مالیات",
  "pages.edit-new-modal-UnitType": "ویرایش واحد",
  "pages.edit-new-modal-Unit": "ویرایش واحد",
  "pages.edit-new-modal-slider": "ویرایش اسلایدر",

  "pages.edit-new-modal-Brand": "ویرایش برند",
  "pages.edit-new-modal-Warehouse": "ورود و خروج محصول ",
  "pages.edit-new-modal-ProductTab": "ویرایش اطلاعات تکمیلی محصول",
  "pages.edit-new-modal-ProductTabDetail": "ویرایش توضیحات تکمیلی",
  "pages.edit-new-modal-InventoryStatus": "ویرایش وضعیت موجودی انبار",
  "pages.edit-new-modal-Metatag": "ویرایش  تگ",
  "pages.edit-new-modal-Producer": "ویرایش تولیدکننده",
  "pages.edit-new-modal-ProductStatus": "ویرایش وضعیت محصول",

  "pages.display-options": "Display Options",
  "pages.orderby": "Order By : ",
  "pages.product-name": "Product Name",
  "pages.name": "نام",
  "pages.lastName": "نام خانوادگی",
  "pages.duration": "Duration",
  "pages.category": "Category",
  "pages.description": "توضیحات",
  "pages.keywords": "کلمات کلیدی",
  "pages.status": "Status",
  "pages.cancel": "انصراف",
  "pages.submit": "ثبت",
  "pages.delete": "پاک کردن",
  "pages.another-action": "Another action",
  "pages.actions": "ACTIONS",
  "pages.header": "Header",
  "pages.details": "DETAILS",
  "pages.orders": "ORDERS",
  "pages.rating": "Rating",
  "pages.price": "Price",
  "pages.ingredients": "Ingredients",
  "pages.is-vegan": "Is Vegan",
  "pages.order-status": "Order Status",
  "pages.bake-progress": "Bake Progress",
  "pages.popularity": "Popularity",
  "pages.comments": "Comments",
  "pages.error-title": "توکن منقضی شده لطفا دوباره لاگین کنید",
  "pages.error-code": "Error code",
  "pages.go-back-home": "لاگین",
  "pages.mailing-info":
    "Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.",
  "pages.invoice-info":
    "Invoice template has an inline styled version for usage outside of the project as well as React version.",
  "pages.react-version": "React Version",
  "pages.inline-version": "Inline Styled Html Version",

  /* 06.Applications */

  /* 06.01.Chat */
  "chat.messages": "Messages",
  "chat.contacts": "Contacts",
  "chat.saysomething": "Say something..",

  /* 06.02.Survey */
  "survey.delete": "Delete",
  "survey.edit": "Edit",
  "survey.add-new": "ADD NEW",
  "survey.add-new-title": "Add New Survey",
  "survey.title": "Title",
  "survey.category": "Category",
  "survey.label": "Label",
  "survey.label-zoneTypeId": "انتخاب بر اساس منطقه",
  "survey.status": "Status",
  "survey.cancel": "Cancel",
  "survey.submit": "Submit",
  "survey.another-action": "Another action",
  "survey.display-options": "Display Options",
  "survey.orderby": "Order By : ",
  "survey.all-surveys": "All Surveys",
  "survey.completed-surveys": "Completed Surveys",
  "survey.categories": "Categories",
  "survey.active-surveys": "Active Surveys",
  "survey.labels": "Labels",

  /* 06.03.Todo */
  "todo.add-new": "ADD NEW",
  "todo.add-new-title": "Add New Todo",
  "todo.title": "Title",
  "todo.detail": "Detail",
  "todo.category": "Category",
  "todo.label": "Label",
  "todo.status": "Status",
  "todo.cancel": "Cancel",
  "todo.submit": "Submit",
  "todo.action": "Action",
  "todo.another-action": "Another action",
  "todo.display-options": "Display Options",
  "todo.orderby": "Order By : ",
  "todo.all-tasks": "All Tasks",
  "todo.pending-tasks": "Pending Tasks",
  "todo.completed-tasks": "Completed Tasks",
  "todo.categories": "Categories",
  "todo.labels": "Labels",

  /* 07.UI */

  /* 07.01.Alerts */
  "alert.rounded": "Rounded Alert",
  "alert.react-notifications": "React Notifications",
  "alert.outline": "Outline",
  "alert.primary": "Primary",
  "alert.secondary": "Secondary",
  "alert.info": "Info",
  "alert.success": "Success",
  "alert.warning": "Warning",
  "alert.error": "Error",
  "alert.filled": "Filled",
  "alert.primary-text": "This is a primary alert—check it out!",
  "alert.secondary-text": "This is a secondary alert—check it out!",
  "alert.success-text": "This is a success alert—check it out!",
  "alert.danger-text": "This is a danger alert—check it out!",
  "alert.warning-text": "This is a warning alert—check it out!",
  "alert.info-text": "This is a info alert—check it out!",
  "alert.light-text": "This is a light alert—check it out!",
  "alert.dark-text": "This is a dark alert—check it out!",
  "alert.default": "Default Alert",
  "alert.dismissing": "Dismissing Alert",
  "alert.dismissing-text": "Holy guacamole! You should check in on some of those fields below.",
  "alert.dismissing-without-animate-text":
    "I am an alert and I can be dismissed without animating!",

  /* 07.02.Badges */
  "badge.sizes": "Sizes",
  "badge.colors": "Colors",
  "badge.outline": "Outline",
  "badge.links": "Links",
  "badge.counter-badges": "Counter Badges",
  "badge.bootstrap-default": "Bootstrap Default",
  "badge.primary": "Primary",
  "badge.secondary": "Secondary",
  "badge.success": "Success",
  "badge.danger": "Danger",
  "badge.warning": "Warning",
  "badge.info": "Info",
  "badge.light": "Light",
  "badge.dark": "Dark",

  /* 07.03.Buttons */
  "button.default": "Bootstrap Default",
  "button.colors": "Colors",
  "button.rounded": "Rounded",
  "button.outline": "Outline",
  "button.states": "States",
  "button.sizes": "Sizes",
  "button.button-groups": "Button Groups",
  "button.large-button": "Large Button",
  "button.small-button": "Small Button",
  "button.extra-small-button": "Extra Small Button",
  "button.block-button": "Block Button",
  "button.active": "Active",
  "button.disabled": "Disabled",
  "button.basic": "Basic",
  "button.toolbar": "Toolbar",
  "button.nesting": "Nesting",
  "button.vertical-variation": "Vertical Variation",
  "button.checkbox-radio-button": "Checkbox and Radio Button",
  "button.checkbox": "Checkbox",
  "button.radio": "Radio",
  "button.radio-button": "Radio Button",
  "button.primary": "Primary",
  "button.secondary": "Secondary",
  "button.success": "Success",
  "button.danger": "Danger",
  "button.warning": "Warning",
  "button.info": "Info",
  "button.light": "Light",
  "button.dark": "Dark",
  "button.states-text":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.click-here": "Click Here",
  "button.states-text-alternate":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.primary-link": "Primary Link",
  "button.link": "Link",
  "button.primary-button": "Primary Button",
  "button.button": "Button",
  "button.left": "Left",
  "button.middle": "Middle",
  "button.right": "Right",
  "button.dropdown": "Dropdown",
  "button.dropdown-link": "Dropdown Link",

  /* 07.04.Cards */
  "cards.icon-card": "Icon Card",
  "cards.image-card": "Image Card",
  "cards.image-overlay-card": "Image Overlay Card",
  "cards.image-card-list": "Image Card List",
  "cards.tab-card": "Tab Card",
  "cards.user-card": "User Card",

  /* 07.05.Carousel */
  "carousel.basic": "Carousel Basic",
  "carousel.single": "Carousel Single",
  "carousel.without-controls": "Carousel Without Controls",

  /* 07.06.Charts */
  "charts.line": "Line Chart",
  "charts.polar": "Polar Chart",
  "charts.area": "Area Chart",
  "charts.scatter": "Scatter Chart",
  "charts.bar": "Bar Chart",
  "charts.radar": "Radar Chart",
  "charts.pie": "Pie Chart",
  "charts.doughnut": "Doughnut Chart",
  "charts.shadow": "Shadow",
  "charts.no-shadow": "No Shadow",

  /* 07.07.Collapse */
  "collapse.basic": "Basic",
  "collapse.toggle": "Toggle",
  "collapse.accordion": "Accordion",
  "collapse.controlled": "Controlled",
  "collapse.uncontrolled": "Uncontrolled",

  /* 07.08.Dropdowns */
  "dropdowns.basic": "Basic",
  "dropdowns.controlled": "Controlled",
  "dropdowns.uncontrolled": "Uncontrolled",
  "dropdowns.dropdown": "Dropdown",
  "dropdowns.header": "Header",
  "dropdowns.action": "Action",
  "dropdowns.another-action": "Another Action",
  "dropdowns.right": "Right",
  "dropdowns.left": "Left",
  "dropdowns.drop-directions": "Drop Directions",
  "dropdowns.dropright": "Dropright",
  "dropdowns.dropleft": "Dropleft",
  "dropdowns.small-button": "Small Button",
  "dropdowns.large-button": "Large Button",
  "dropdowns.sizing": "Sizing",
  "dropdowns.split-button": "Split Button Dropdowns",
  "dropdowns.dropup": "Dropup",

  /* 07.09.Editors */
  "editors.draftjs": "Draft.js",
  "editors.quill-standart": "Quill Standard",
  "editors.quill-bubble": "Quill Bubble",

  /* 07.10.Forms */
  "forms.basic": "Basic",
  "forms.email": "E-mail",
  "forms.email-muted": "We'll never share your email with anyone else.",
  "forms.password": "Password",
  "forms.submit": "Submit",
  "forms.horizontal": "Horizontal",
  "forms.radios": "Radios",
  "forms.first-radio": "First radio",
  "forms.second-radio": "Second radio",
  "forms.third-radio-disabled": "Third disabled radio",
  "forms.checkbox": "Checkbox",
  "forms.signin": "Sign in",
  "forms.top-labels-over-line": "Top Labels Over Line",
  "forms.tags": "Tags",
  "forms.date": "Date",
  "forms.state": "State",
  "forms.top-labels-in-input": "Top Labels In Input",
  "forms.email-u": "E-MAIL",
  "forms.password-u": "PASSWORD",
  "forms.tags-u": "TAGS",
  "forms.date-u": "DATE",
  "forms.state-u": "STATE",
  "forms.grid": "Form Grid",
  "forms.address": "Address",
  "forms.address2": "Address 2",
  "forms.city": "City",
  "forms.city-message": "Please select a city!",
  "forms.state-message": "Please select a state!",
  "forms.zip": "Zip",
  "forms.signup": "Signup",
  "forms.inline": "Inline",
  "forms.validation-availity": "Availity Reactstrap Validation",
  "forms.validation-formik": "Formik Validation",
  "forms.default": "Default",
  "forms.firstname": "First name",
  "forms.firstname-message": "Please enter your first name!",
  "forms.lastname": "Last name",
  "forms.lastname-message": "Please enter your last name!",

  /* 07.11.Form Components */
  "form-components.custom-inputs": "Custom Inputs",
  "form-components.checkboxes": "Checkboxes",
  "form-components.radios": "Radios",
  "form-components.inline": "Inline",
  "form-components.react-select": "React Select",
  "form-components.state-single": "State Single",
  "form-components.state-multiple": "State Multiple",
  "form-components.react-autosuggest": "React Autosuggest",
  "form-components.date-picker": "Date Picker",
  "form-components.date": "Date",
  "form-components.date-range": "Date Range",
  "form-components.date-with-time": "Date with Time",
  "form-components.embedded": "Embedded",
  "form-components.dropzone": "Dropzone",
  "form-components.drop-files-here": "Drop Files Here",
  "form-components.tags": "Tags",
  "form-components.switch": "Switch",
  "form-components.primary": "Primary",
  "form-components.secondary": "Secondary",
  "form-components.primary-inverse": "Primary Inverse",
  "form-components.secondary-inverse": "Secondary Inverse",
  "form-components.slider": "Slider",
  "form-components.double-slider": "Double Slider",
  "form-components.single-slider": "Single Slider",
  "form-components.rating": "Rating",
  "form-components.interactive": "Interactive",
  "form-components.readonly": "Readonly",
  "form-components.type-a-cake": "Type a Cake",
  "form-components.start": "Start",
  "form-components.end": "End",
  "form-components.tables": "Tables",
  /* 07.12.Icons */
  "icons.simplelineicons": "Simple Line Icons",
  "icons.iconsmind": "Iconsmind Icons",

  /* 07.13.Input Groups */
  "input-groups.basic": "Basic",
  "input-groups.sizing": "Sizing",
  "input-groups.small": "Small",
  "input-groups.default": "Default",
  "input-groups.large": "Large",
  "input-groups.checkboxes-and-radios": "Checkboxes and radios",
  "input-groups.multiple-inputs": "Multiple Inputs",
  "input-groups.first-and-last-name": "First and last name",
  "input-groups.multiple-addons": "Multiple Addons",
  "input-groups.button-addons": "Button Addons",
  "input-groups.button": "Button",
  "input-groups.buttons-with-dropdowns": "Buttons with Dropdowns",
  "input-groups.dropdown": "Dropdown",
  "input-groups.header": "Header",
  "input-groups.action": "Action",
  "input-groups.another-action": "Another Action",
  "input-groups.custom-select": "Custom Select",
  "input-groups.options": "Options",
  "input-groups.choose": "Choose...",
  "input-groups.custom-file-input": "Custom File Input",

  /* 07.14.Jumbotron */
  "jumbotron.hello-world": "Hello, world!",
  "jumbotron.lead":
    "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
  "jumbotron.lead-detail":
    "It uses utility classes for typography and spacing to space content out within the larger container.",
  "jumbotron.learn-more": "Learn more",

  /* 07.15.Modal */
  "modal.basic": "Basic",
  "modal.modal-title": "Modal title",
  "modal.launch-demo-modal": "Launch Demo Modal",
  "modal.scrolling-long-content": "Scrolling Long Content",
  "modal.backdrop": "Backdrop",
  "modal.backdrop-value": "Backdrop value",
  "modal.right-modal": "Right Modal",
  "modal.launch-right-modal": "Launch Right Modal",
  "modal.nested-modal": "Nested Modal",
  "modal.size": "Size",
  "modal.launch-large-modal": "Launch Large Modal",
  "modal.launch-small-modal": "Launch Small Modal",

  /* 07.16.Navigation */
  "nav.basic": "Nav Basic",
  "nav.active": "Active",
  "nav.disabled": "Disabled",
  "nav.disabled-link": "Disabled Link",
  "nav.link": "Link",
  "nav.longer-link": "Longer nav link",
  "nav.another-link": "Another Link",
  "nav.right": "Right",
  "nav.dropdown": "Dropdown",
  "nav.header": "Header",
  "nav.action": "Action",
  "nav.another-action": "Another Action",
  "nav.horizontal-alignment": "Nav Horizontal Alignment",
  "nav.vertical-alignment": "Nav Vertical Alignment",
  "nav.pills": "Nav Pills",
  "nav.fill-justify": "Nav Fill and Justify",
  "nav.pills-dropdowns": "Nav Pills with Dropdowns",
  "nav.pagination-basic": "Pagination Basic",
  "nav.pagination-sizing": "Pagination Sizing",
  "nav.large": "Large",
  "nav.small": "Small",
  "nav.center": "Center",
  "nav.pagination-alignment": "Pagination Alignment",
  "nav.breadcrumb-basic": "Breadcrumb Basic",

  /* 07.17.Popover & Tooltip */
  "popover-tooltip.popover": "Popover",
  "popover-tooltip.tooltip": "Tooltip",

  /* 07.18.Sortable */
  "sortable.columns": "Sorting Columns",
  "sortable.basic": "Basic",
  "sortable.handles": "Handles",

  /* 07.19.Maps */
  "maps.google": "Google",
  "maps.yandex": "Yandex",

  /* 07.20.Tables */
  "table.bootstrap-tables": "Bootstrap Tables",
  "table.bootstrap-basic": "Basic Table",
  "table.bootstrap-striped": "Striped Rows",
  "table.bootstrap-bordered": "Bordered Table",
  "table.bootstrap-borderless": "Borderless Table",
  "table.bootstrap-hoverable": "Hoverable Rows",
  "table.bootstrap-responsive": "Responsive Table",
  "table.react-tables": "React Tables",
  "table.react-pagination": "Pagination",
  "table.react-scrollable": "Scrollable",
  "table.react-advanced": "Filter, Length and Jump",

  "name-moti": "نام",
  areaCode: "کد منطقه",
  latitude: "عرض جغرافیایی(lat) ",
  longitude: "عرض جغرافیایی(lng) ",
  ZoneType: "نوع موقعیت مکانی",
  تصاویر: "تصویر",
  "description-moti": "توضیحات",
  "time-moti": " مدت دوره",
  "announcer-moti": "گوینده",
  "writer-moti": "نویسنده",
  "pages-moti": "تعداد صفحات",
  "translator-moti": " مترجم",
  "editor-moti": " چاپ",
  "sex-moti": " جنسیت",
  "degree-moti": " مدرک تحصیلی",
  "phoneNumber-moti": " شماره همراه",
  "duration-moti": " مدت اشتراک",
  "color-moti": " کد رنگ",
  "price-moti": " هزینه دوره ",
  "title-moti": " عنوان",
  "title-link": " لینک",
  "title-ratio": " نرخ عنوان",
  "title-order": " ترتیب",
  "content-moti": " نوع محتوا",
  "advice-moti": "  پیشنهاد شده",
  "publisher-moti": " ناشر",
  "file-moti": " فایل",
  "user-moti": " نام کاربر",
  "categoryName-moti": "نام دسته بندی",
  "categoryN-moti": "دسته بندی:",
  "organize-moti": "مرتب سازی:",
  "sub-moti": " اشتراک",
  "prpriority-moti": " اولویت",
};
