/** @format */

import React, { Component } from "react";
import {
  // CustomInput,
  // Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { Formik, Field, Form } from "formik";

// import Select from "react-select";
// import CustomSelectInput from "../../../../../../components/common/CustomSelectInput";
import IntlMessages from "../../../../../../helpers/IntlMessages";

import { Station } from "../../../../../../api/Api";

export default class AddConstant extends Component {
  state = {
    loading: false,
    initialValues: {
      name: "",
    },
    file: "",
  };

  handleSubmit = async (values, { resetForm, setSubmitting, setValues }) => {
    this.setState({ loading: true });
    try {
      const newValues = { ...values, file: this.state.file };
      await Station.addStation(newValues);

      //closing addNew Component
      this.props.toggleModal();

      //fetching data from dataBase
      this.props.dataListRender();

      // if (!this.state.isNew) {
      //   Swal.close();
      // }
      resetForm();
    } catch (error) {
      console.error(error);
      // toast(error.response.data.message);
    }
    this.setState({ loading: false });
  };

  handleChangeFile = ({ target }) => {
    console.log(target.files[0]);
    this.setState({ file: target.files[0] });
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.props.modalOpen}
          toggle={this.props.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          {this.state.loading ? <div className="loading" /> : ""}
          <ModalHeader toggle={this.props.toggleModal}>ثبت ایستگاه</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.initialValues}
              // validationSchema={this.ValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form className="form" onSubmitCapture={props.handleSubmit}>
                  <Label className="mt-4">نام ایستگاه</Label>
                  <Field
                    name="name"
                    placeholder="نام ایستگاه"
                    className="form-control"
                    type="text"
                    required
                  />
                  <Label className="mt-4">latitude</Label>
                  <Field
                    name="latitude"
                    placeholder="latitude"
                    className="form-control"
                    type="number"
                    required
                  />
                  <Label className="mt-4">longitude</Label>
                  <Field
                    name="longitude"
                    placeholder="longitude"
                    className="form-control"
                    type="number"
                    required
                  />
                  <Label className="mt-4">file</Label>
                  <Field
                    name="file"
                    placeholder="انتخاب فایل"
                    className="form-control"
                    type="file"
                    onChange={(e) => this.handleChangeFile(e)}
                  />

                  <ModalFooter>
                    <Button color="secondary" outline onClick={this.props.toggleModal}>
                      <IntlMessages id="pages.cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                      <IntlMessages id="pages.submit" />
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
