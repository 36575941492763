const cakes = [
  {
    title: 'Marble Cake',
    link: '/app/pages/details'
  },
  {
    title: 'Fruitcake',
    link: '/app/pages/details'
  },
  {
    title: 'Chocolate Cake',
    link: '/app/pages/details'
  },
  {
    title: 'Fat Rascal',
    link: '/app/pages/details'
  },
  {
    title: 'Financier',
    link: '/app/pages/details'
  },
  {
    title: 'Genoise',
    link: '/app/pages/details'
  },
  {
    title: 'Gingerbread',
    link: '/app/pages/details'
  },
  {
    title: 'Goose Breast',
    link: '/app/pages/details'
  },
  {
    title: 'Parkin',
    link: '/app/pages/details'
  },
  {
    title: 'Salzburger Nockerl',
    link: '/app/pages/details'
  },
  {
    title: 'Soufflé',
    link: '/app/pages/details'
  },
  {
    title: 'Merveilleux',
    link: '/app/pages/details'
  },
  {
    title: 'Streuselkuchen',
    link: '/app/pages/details'
  },
  {
    title: 'Tea Loaf',
    link: '/app/pages/details'
  },
  {
    title: 'Napoleonshat',
    link: '/app/pages/details'
  },
  {
    title: 'Merveilleux',
    link: '/app/pages/details'
  },
  {
    title: 'Magdalena',
    link: '/app/pages/details'
  },
  {
    title: 'Cremeschnitte',
    link: '/app/pages/details'
  },
  {
    title: 'Cheesecake',
    link: '/app/pages/details'
  },
  {
    title: 'Bebinca',
    link: '/app/pages/details'
  },
  {
    title: 'Fruitcake',
    link: '/app/pages/details'
  },
  {
    title: 'Chocolate Cake',
    link: '/app/pages/details'
  },
  {
    title: 'Fat Rascal',
    link: '/app/pages/details'
  },
  {
    title: 'Streuselkuchen',
    link: '/app/pages/details'
  }
]
export default cakes
