/** @format */

import { APIClient } from "./_api";

const endpoint = "/BusLine";

export function getAllBusLine() {
  return APIClient.get(`${endpoint}`);
}

export function addBusLine(data) {
  return APIClient.post(`${endpoint}/`, data);
}

export function editBusLine(data, id) {
  return APIClient.put(`${endpoint}/${id}`, data);
}

export function deleteBusLine(id) {
  return APIClient.delete(`${endpoint}/soft/${id}`);
}
