/** @format */

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Bus from "./Bus";
import Station from "./Station";
import BusLine from "./BusLine";

const Pages = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/Bus`} />
      <Route path={`${match.url}/Bus`} component={Bus} />
      <Route path={`${match.url}/Station`} component={Station} />
      <Route path={`${match.url}/BusLine`} component={BusLine} />

      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Pages;
