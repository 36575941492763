/** @format */

import { APIClient } from "./_api";

const endpoint = "/Bus";

export function getAllBus() {
  return APIClient.get(`${endpoint}`);
}

export function addBus(data) {
  return APIClient.post(`${endpoint}`, data);
}

export function editBus(data, id) {
  return APIClient.put(`${endpoint}/${id}`, data);
}

export function deleteBus(id) {
  return APIClient.delete(`${endpoint}/${id}`);
}
