/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
// import "./helpers/Firebase";
import AppLocale from "./lang";

import NotificationContainer from "./components/common/react-notifications/NotificationContainer";

import main from "./views";
import app from "./views/app";
import user from "./views/user";
import error from "./views/error";
import { getDirection } from "./helpers/Utils";
import "@progress/kendo-theme-default/dist/all.css";
import { NotificationManager } from "./components/common/react-notifications";

import { APIClient, setAuthToken } from "./api/_api";

// import "../node_modules/rsuite/dist/styles/rsuite-default-rtl.css";
const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  let token = localStorage.getItem("auth");

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  UNSAFE_componentWillMount() {
    const token = localStorage.getItem("auth");
    if (token) {
      setAuthToken(token);
    }
  }

  componentWillMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
  }

  createNotification = (type, className, message) => {
    let cName = className || "";
    return (() => {
      switch (type) {
        case "primary":
          NotificationManager.primary(
            "This is a notification!",
            "Primary Notification",
            3000,
            null,
            null,
            cName,
          );
          break;
        case "secondary":
          NotificationManager.secondary(
            "This is a notification!",
            "Secondary Notification",
            3000,
            null,
            null,
            cName,
          );
          break;
        case "info":
          NotificationManager.info("Info message", "", 3000, null, null, cName);
          break;
        case "success":
          NotificationManager.success("با موفقیت انجام شد", "", 3000, null, null, cName);
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000,
            null,
            null,
            cName,
          );
          break;
        case "error":
          NotificationManager.error(
            message ? message : "با خطا مواجه شد",
            "",
            5000,
            () => {
              alert("callback");
            },
            null,
            cName,
          );
          break;
        default:
          NotificationManager.info("Info message");
          break;
      }
    })();
  };

  componentDidMount() {
    APIClient.interceptors.response.use(
      (response) => {
        if (response.data && response.status === 200 && response.data.message) {
          this.createNotification("success", "filled", response.data.message);
        }
        if (response.data && response.status === 201) {
          this.createNotification("success", "filled", response.status);
          this.setState({
            open: true,
            message: response.data.message,
            variant: "success",
          });
        }
        return response;
      },
      (error) => {
        if (error) {
          this.createNotification(
            "error",
            "filled",
            error && error.response && error.response.data && error.response.data.message,
          );
        }

        if (error.response && error.response.status && error.response.status === 401) {
          window.location.replace("/login");
          // this.setState({
          //   open: true,
          //   message: error.response.data.Message,
          //   variant: "error",
          // });
        }
        return Promise.reject(error);
      },
    );
  }

  render() {
    const { locale, loginUser } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <div className="h-100">
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <React.Fragment>
            <NotificationContainer />
            <Router>
              <Switch>
                <AuthRoute path="/app" authUser={loginUser} component={app} {...this.props} />
                <Route path="/user" component={user} />
                <Route path="/error" exact component={error} />
                <Route path="/" exact component={main} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user: loginUser } = authUser;
  const { locale } = settings;
  return { loginUser, locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
