/** @format */

import React, { Component } from "react";
import {
  // CustomInput,
  // Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { Formik, Form } from "formik";
import { SelectPicker } from "rsuite";

// import Select from "react-select";
// import CustomSelectInput from "../../../../../../components/common/CustomSelectInput";
import IntlMessages from "../../../../../../helpers/IntlMessages";

import { Bus, BusLine } from "../../../../../../api/Api";

export default class EditConstant extends Component {
  state = {
    loading: false,
    initialValues: {
      name: this.props.product.name,
    },
    busLines: [],
    busLine: "",
  };

  handleSubmit = async (values, { resetForm, setSubmitting, setValues }) => {
    this.setState({ loading: true });
    try {
      await Bus.editBus({ busLineId: this.state.busLine }, this.props.product.id);

      //closing addNew Component
      this.props.toggleModal();

      //fetching data from dataBase
      this.props.dataListRender();

      // if (!this.state.isNew) {
      //   Swal.close();
      // }
      resetForm();
    } catch (error) {
      console.error(error);
      // toast(error.response.data.message);
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.getAll();
  }
  getAll = async () => {
    try {
      const { data, status } = await BusLine.getAllBusLine();
      if (status !== 200) {
        return;
      }
      const newData = data.data.map((x) => {
        return { value: x.id, label: x.name };
      });
      this.setState({ busLines: newData });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (e) => {
    this.setState({ busLine: e });
  };
  render() {
    const { busLines, busLine } = this.state;
    return (
      <>
        <Modal
          isOpen={this.props.modalOpen}
          toggle={this.props.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          {this.state.loading ? <div className="loading" /> : ""}
          <ModalHeader toggle={this.props.toggleModal}>
            <IntlMessages id="pages.edit-new-modal-Currency" />
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={this.state.initialValues}
              // validationSchema={this.ValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <Form className="form" onSubmitCapture={props.handleSubmit}>
                  <Label className="mt-4">خط</Label>
                  <SelectPicker
                    name="busLineId"
                    placeholder="خط"
                    className="form-control"
                    type="text"
                    data={busLines}
                    value={busLine}
                    onChange={this.handleChange}
                    required
                  />

                  <ModalFooter>
                    <Button color="secondary" outline onClick={this.props.toggleModal}>
                      <IntlMessages id="pages.cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                      <IntlMessages id="pages.submit" />
                    </Button>{" "}
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>

          {/* <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Label className="mt-4">
            <IntlMessages id="pages.name" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.price" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.duration" />
          </Label>
          <Input />
          <Label className="mt-4">
            <IntlMessages id="pages.category" />
          </Label>
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name="form-field-name"
            options={categories}
          />
          <Label className="mt-4">
            <IntlMessages id="pages.description" />
          </Label>
          <Input type="textarea" name="text" id="exampleText" />
          <Label className="mt-4">
            <IntlMessages id="pages.status" />
          </Label>
          <CustomInput
            type="radio"
            id="exCustomRadio"
            name="customRadio"
            label="ON HOLD"
          />
          <CustomInput
            type="radio"
            id="exCustomRadio2"
            name="customRadio"
            label="PROCESSED"
          />
        </ModalBody> */}
          {/* <ModalFooter>
          <Button color="secondary" outline onClick={toggleModal}>
            <IntlMessages id="pages.cancel" />
          </Button>
          <Button color="primary" onClick={toggleModal}>
            <IntlMessages id="pages.submit" />
          </Button>{" "}
        </ModalFooter> */}
        </Modal>
      </>
    );
  }
}
